<template>
  <div class="avatar">
		<p>{{text}}</p>
	</div>
</template>

<script>
export default {
	props: {
		text: String,
	},
	data(){
		return{
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.avatar{
	height: 30px;
	width: 30px;
	background: var(--avatar-background);
	border-radius: 50%;
	border: 3px solid #fff;

	p{
		line-height: 30px;
		width: 30px;
		text-align: center;
		margin: 0;
		font-family: 'Montserrat', sans-serif;
		font-weight: bold;
		font-size: 12px;
		color: var(--avatar-color);
	}
}
</style>

<!-- Used in : AR -->